import axios from 'axios'

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_PORT + '/api',
    validateStatus: () => true,
})

instance.interceptors.request.use((config) => {
    config.headers['Content-Type'] = 'application/json; charset=UTF-8';
    config.headers.Authorization = window.localStorage.getItem('token')
    config.headers['X-Client-Id'] = 4051

    return config
})

export default instance
