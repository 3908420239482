import React from 'react'
import {Link, NavLink, useNavigate} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { checkIsAuth, logout } from '../redux/features/auth/authSlice'
import { toast } from 'react-toastify'
import Logo from "../../src/img/favicon.svg"

export const Navbar = () => {
    const isAuth = useSelector(checkIsAuth)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const activeStyles = {
        color: 'white',
    }

    const logoutHandler = () => {
        dispatch(logout())
        window.localStorage.removeItem('token')
        toast('Դուք դուրս եք եկել')
    }

    return (
        <div className='flex py-4 justify-between items-center pl-20 pr-20'>
            <span className='flex justify-center items-center text-xs rounded-sm cursor-pointer' onClick={() => {
                navigate("/")
            }}>
                <img alt={"'_'"} src={Logo} />
            </span>

            {isAuth && (
                <ul className='flex gap-8'>
                    <li>
                        <NavLink
                            to={'/'}
                            href='/'
                            className='text-xs text-gray-400 hover:text-white'
                            style={({ isActive }) =>
                                isActive ? activeStyles : undefined
                            }
                        >
                            Գլխավոր
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={'/posts'}
                            href='/'
                            className='text-xs text-gray-400 hover:text-white'
                            style={({ isActive }) =>
                                isActive ? activeStyles : undefined
                            }
                        >
                            Իմ գրառումները
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={'/new'}
                            href='/'
                            className='text-xs text-gray-400 hover:text-white'
                            style={({ isActive }) =>
                                isActive ? activeStyles : undefined
                            }
                        >
                            Ավելացնել գրառում
                        </NavLink>
                    </li>
                </ul>
            )}

            <div className='flex justify-center items-center bg-gray-600 text-xs text-white rounded-sm px-4 py-2'>
                {isAuth ? (
                    <button onClick={logoutHandler}>Դուրս գալ</button>
                ) : (
                    <Link to={'/login'}>Մուտք գործել</Link>
                )}
            </div>
        </div>
    )
}
